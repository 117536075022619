/* eslint-disable */
import CheckInService from '../../api/CheckInService.js'
import MD5 from 'js-md5'

export default{
	data(){
		return{
			agreeIsBtn:false,
			isAgree:false,
			userOptions:[{value:'0',label:'个人用户'},{value:'1',label:'机构用户'}],
			UserType: '', //用户类型
			userPhone:'', //用户手机号
			userCode:'', //验证码
			userPassword:'', //密码
			userConfirmPass:'', //确认密码
			isGetCode:true,//是否发送验证码
			codeTime:'',
			sendCode:'',//发送验证码
		}
	},
	methods:{
		getCode(){ //获取验证码
			var that = this
			if(that.userPhone == ''){
				$('#registerPhone').text('请输入手机号')
			}else if(!(/^1[3456789]\d{9}$/.test(that.userPhone))){
				$('#registerPhone').text('请输入正确格式的手机号')
			}else{
				$('#registerPhone').text('')
				CheckInService.SendPhoneValidCode(that.userPhone.trim()).then(res=>{
					console.log(res)
					if(res.Tag  == 1){
						that.sendCode = res.Description
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
				that.isGetCode = false;
				that.codeTime = 60;
				var setInterv = setInterval(function() {
					that.codeTime--;
					if (that.codeTime < 0) {
						that.codeTime = 60
						clearInterval(setInterv)
						that.isGetCode = true
					}
				}, 1000)
			}
		},
		aggreeProtical(){ //同意注册协议
			if(this.agreeIsBtn){
				this.isAgree = true
			}
		},
		toLogin(){ //登录
			this.$router.push({
				path:"/Login"
			})
		},
		UserRegister(){ //注册
			var that = this
			if(that.UserType == ''){
				$('#registerType').addClass('error')
				$('#registerType .errorts').show()
				$('#registerType .errorts').text('请选择用户类型')
			}else if(that.userPhone.trim() == ''){
				$('#registerPhone').text('请输入手机号')
				$('#registerType').removeClass('error')
				$('#registerType .errorts').hide()
			}else if(!(/^1[3456789]\d{9}$/.test(that.userPhone.trim()))){
				$('#registerPhone').text('请输入正确格式的手机号')
				$('#registerType').removeClass('error')
				$('#registerType .errorts').hide()
			}else if(that.userCode == ''){
				$('#registerCode').text('请输入手机验证码')
				$('#registerType').removeClass('error')
				$('#registerType .errorts').hide()
				$('#registerPhone').text('')
			}else if(that.userCode != that.sendCode){
				$('#registerCode').text('请输入正确手机验证码')
				$('#registerType').removeClass('error')
				$('#registerType .errorts').hide()
				$('#registerPhone').text('')
			}else if(that.userPassword.trim() == ''){
				$('#registerPass').text('请输入密码')
				$('#registerCode').text('')
				$('#registerType').removeClass('error')
				$('#registerType .errorts').hide()
				$('#registerPhone').text('')
			}else if(that.userPassword.trim().length < 6 || that.userPassword.trim().length > 20){
				$('#registerPass').text('请输入6-20字符的密码')
				$('#registerCode').text('')
				$('#registerType').removeClass('error')
				$('#registerType .errorts').hide()
				$('#registerPhone').text('')
			}else if(that.userConfirmPass.trim() == ''){
				$('#confirmPass').text('请输入确认密码')
				$('#registerPass').text('')
				$('#registerCode').text('')
				$('#registerType').removeClass('error')
				$('#registerType .errorts').hide()
				$('#registerPhone').text('')
			}else if(that.userConfirmPass.trim() != that.userPassword.trim()){
				$('#confirmPass').text('两次输入的密码不一致')
				$('#registerPass').text('')
				$('#registerCode').text('')
				$('#registerType').removeClass('error')
				$('#registerType .errorts').hide()
				$('#registerPhone').text('')
			}else{
				$('#registerType').removeClass('error')
				$('#registerType .errorts').hide()
				$('#registerPhone').text('')
				$('#registerCode').text('')
				$('#confirmPass').text('')
				$('#registerPass').text('')
				CheckInService.UserRegister(that.userPhone.trim(),MD5(that.userPassword.trim()),that.UserType,that.userCode).then(res=>{
					if(res.Tag == 1){
						that.$alert('注册成功', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								if (action == "confirm") {
									that.$router.push({
										path: '/Login'
									});
								}
							}
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}
			
		}
	},
	mounted(){
		var that = this
		$(".zcxy_detail").scroll(function() {
			var scroH = $(this).scrollTop(); //滚动高度
			var viewH = $(this).height(); //可见高度
			var contentH = $(this).get(0).scrollHeight; //内容高度
			if(scroH >= contentH - viewH - 40) {
				that.agreeIsBtn = true
				$(".conbtn_boxc a").removeClass('e0cdbf');
			}
		});
	}
}